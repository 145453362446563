import 'owl.carousel';
import 'magnific-popup';
import moment from 'moment';

jQuery( document ).ready( function ( $ ) {
	// Nav
	$( 'nav>ul:first-child>li>a:not(.external)' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		$( 'html,body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 1000 );
	} );

	// Bio
	$( '#bio a' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );

	// Tour
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/id_15489300/events?app_id=45PRESS_JADEN_HAMILTON',
		method: 'GET',
		dataType: 'json',
		xhrFields: {
			withCredentials: false
		},
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';

			if ( data.length ) {
				for ( let event of data ) {
					const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;

					html += '<div data-bit-id="' + event.id + '">';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-location">' + event_location + '</div>';
					html += '<div class="event-links">';
					if ( event.offers ) {
						for ( let offer of event.offers ) {
							if ( offer.type === 'Tickets' ) {
								html += '<a href="' + offer.url + '" target="_blank" class="btn">Tickets</a>';
								break;
							}
						}
					}

					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );

	// Music
	$( '#music .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );

	// Videos
	$( '#videos .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		video: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fa fa-chevron-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fa fa-chevron-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );

	// Instagram
	$.ajax( {
		method: 'GET',
		url: 'https://www.juicer.io/api/feeds/jadenhamiltonmusic',
		dataType: 'json',
		success: ( data ) => {
			if ( data ) {
				let i = 0;
				let html = '';
				for ( let item of data.posts.items ) {
					html += '<div>';
					html += '<a href="' + item.full_url + '" target="_blank">';
					html += '<img src="' + item.image + '" alt="' + item.id + '" />';
					html += '</a>';
					html += '</div>';
					i ++;
					if ( i >= 8 ) {
						break;
					}
				}

				$( '#instagram-feed' ).html( html );
			}
		},
		error: () => {
			console.log( 'An error has occurred fetching IG photos.' );
		}
	} );
} );
